(function(window) {
    var shop_name = "{{ shop_data.shop_name }}";
    var shop_color_text = "{{ shop_data.shop_color_text }}";
    var _placeholder = "{{ section.settings.home_search_placeholder }}";
    var autocomplete = false;
    var getAlgoliaResults = false;
    var _algolia_search = false;
    var _client = false;
    var autocomplete_added = false; 
    
    function get_item_template({ item, html, components }) {
        //console.log('item', item);
        var compare_price = '$' + parseFloat(item.compare_at_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var item_price = '$' + parseFloat(item.price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var on_sale = false;
        var sale_class = 'hidden';

        if (item.compare_at_price > item.price) {
            on_sale = true;
            sale_class = 'on_sale';
        }
        return html `
            <a class="aa-ItemLink" href="/products/${item.handle}">
                <div class="aa-ItemContent">
                    <div class="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
                        <img src="${item.image}&width=80" alt="${item.title}" width="80" height="80"/>
                    </div>
                    <div class="aa-ItemContentBody">
                        <div class="aa-ItemContentTitle">${components.Highlight({hit: item, attribute: 'title'})}</div>
                        <div class="aa-ItemContentDescription">Sku: ${(item.sku.length === 5) ? components.Highlight({hit: item, attribute: 'sku'}) : components.Highlight({hit: item, attribute:['meta','netsuite','parent_skux']}) }</div>
                        <div class="aa-ItemContentDescription">Price: <span class="${ sale_class }">${ compare_price }</span> ${ item_price }</div>
                    </div>
                </div>
            </a>`;
    }


    function add_autocomplete() {
        autocomplete_added = true;
        _client = algoliasearch('XO6QV027C6', '12c2bf2cb6f3a4ce0737bb51901af11b');
        autocomplete = window['@algolia/autocomplete-js'].autocomplete;
        getAlgoliaResults = window['@algolia/autocomplete-js'].getAlgoliaResults;

        function get_shop_source(o) {
            var shop_source = {
                sourceId: 'shop_source',
                getItems: function() {
                    return getAlgoliaResults({
                        searchClient: _client,
                        queries: [{
                            indexName: 'shopify_collections',
                            query: o.query,
                            params: {
                                hitsPerPage: 10,
                                filters: "meta.global.collection_type:shop"
                            }
                        }]
                    });
                },
                getItemUrl: function({ item }) {
                    return '/collections/' + item.handle;
                },
                templates: {
                    item: function({item, html}) {
                        return html`
                        <a class="shop_link cf" href="/collections/${ item.handle }">
                            <span class="shop_icon" style="background-image: url(https://img.mlahart.com/${ item.meta.global.lpcode }-menu.30.png)"></span>
                            <span class="shop_name">${ item.meta.global.shop_name }</span>
                        </a>`;
                    }
                },
                onSelect: function(o){
                    mL.event('ml_home_search_selection',{ text: o.item.title, query: o.state.query });
                }
            };
            return shop_source;
        }

        function get_other_shop_source(o) {
            var other_shop_source = {
                sourceId: 'other_shop_source',
                getItems: function() {
                    return getAlgoliaResults({
                        searchClient: _client,
                        queries: [{
                            indexName: 'shopify_products',
                            query: o.query,
                            params: {
                                hitsPerPage: 5
                            }
                        }]
                    });
                },
                getItemUrl: function({ item }) {
                    return '/products/' + item.handle;
                },
                templates: {
                    item: get_item_template,
                    noResults: function(o) {
                        //console.log('templates noResults - o', o);
                        return o.html `<div class="no_results">No results for "${ o.state.query }"</div>`;
                    },
                    header: function(o) {
                        if (o.items.length === 0 || o.state.query.length < 1) return;     
                            return o.html `<div class="mlahart_dark_row">Item Results for "${ o.state.query }"</div>`;                        
                    },
                    footer: function(o) {
                        if (o.state.query.length < 1 || o.items.length === 0) return;
                        return o.html `
                        <div class="mlahart_dark_row">
                            <a title="Click to view all results" href="/search?query=${ o.state.query }">Click to view all results 
                                <svg aria-hidden="true" focusable="false" role="presentation" class="mlahart_chevron_right ico_xsm" viewBox="0 0 284.49 498.98">
                                    <path d="M35 498.98a35 35 0 0 1-24.75-59.75l189.74-189.74L10.25 59.75a35.002 35.002 0 0 1 49.5-49.5l214.49 214.49a35 35 0 0 1 0 49.5L59.75 488.73A34.89 34.89 0 0 1 35 498.98z"></path>
                                </svg>
                            </a>
                        </div>`;
                    }
                },
                onSelect: function(o){
                    mL.event('ml_home_search_selection',{ text: o.item.title, query: o.state.query  });
                }
            };
            return other_shop_source;
        }


        function get_sources(o) {
            const sources = [];

            let shop_source = get_shop_source(o);

            sources.push(shop_source);

            if (o.query.length > 0) {
                let other_shop_source = get_other_shop_source(o);
                sources.push(other_shop_source);
            }

            return sources;
        }

        /*
        function get_params() {
            try {
                return new URL(window.location).searchParams.get('query') || '';                
            } catch (err) {
                return '';
            }
            return '';
        }

        var init_query = get_params();

        var autoFocus = init_query.length > 0;
        */

        var active = false;

        function update_label(){
            if(active) return;

            active = true;

            setTimeout(function(){
                var input_el = document.querySelector('input.aa-Input');
                var div_el = document.querySelector('div.aa-Autocomplete');
                var placeholder = _placeholder;
                var label = document.getElementById('autocomplete-0-label');

                if(label && !label.querySelector('span')){
                    var span = document.createElement('span');
                    span.className = 'visually-hidden';
                    span.innerText = placeholder;
                    label.appendChild(span);
                }

                if(input_el && placeholder){
                    if(input_el.hasAttribute('aria-labelledby')) {
                        input_el.removeAttribute('aria-labelledby');
                    }

                    if(!input_el.hasAttribute('aria-label')){
                        input_el.setAttribute('aria-label', placeholder);
                    }                
                }

                if(div_el && placeholder){
                    if(div_el.hasAttribute('aria-labelledby')) {
                        div_el.removeAttribute('aria-labelledby');
                    }

                    if(!div_el.hasAttribute('aria-label')){
                        div_el.setAttribute('aria-label', placeholder);
                    }                
                }

                active = false;

            },500);
        }

        var event_recorded = false;

        var autocomplete_config = {
            container: '#autocomplete',
            //panelContainer: '#autocomplete_panel',
            panelPlacement: 'input-wrapper-width',
            placeholder: _placeholder,
            getSources: get_sources,
            debug: false,
            openOnFocus: true,
            autoFocus: false,
            enterKeyHint: 'search',
            onStateChange({ state }){  
            //console.log('state', state) 
                if(!event_recorded && state.query.length > 0){
                    mL.event('ml_home_search_used', { page: window.location.href, text: state.query });
                    event_recorded = true;
                }             
                update_label();                            
            }
        };
        _algolia_search = autocomplete(autocomplete_config);

        update_label();
    }

    function have_scripts_loaded() {
        return typeof algoliasearch === 'function' && typeof window['@algolia/autocomplete-js'] === 'object';
    }

    function start_app() {
        if (have_scripts_loaded()) {
            if(!autocomplete_added) add_autocomplete();
        } else {
            window.setTimeout(function() {
                if (have_scripts_loaded()) {
                    if(!autocomplete_added) add_autocomplete();
                } else {
                    document.getElementById('autocomplete').innerHTML = '<div style="text-align:center;color:#fff;line-height:50px;">Search is temporarily unavailable</div>';
                }
            }, 1000);
        }
    }

    document.addEventListener("DOMContentLoaded", start_app);
    window.addEventListener('load', start_app);


}(window));